.form-top-holder {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 30px;
}

.form-top-holder .input-dropdown-holder {
  max-width: 250px;
  width: 100%;
  position: relative;
}

.form-top-holder .input-dropdown-holder input[type='text'] {
  padding: 2px 8px;
  color: #333333;
  height: 38px;
  font-size: 14px;
  width: 100%;
}

.form-top-holder .input-dropdown-holder .users-options-holder {
  position: absolute;
  top: 44px;
  z-index: 3;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  padding: 12px 8px;
  overflow-y: auto;
  max-height: 350px;
}

.form-top-holder .input-dropdown-holder .users-options-holder li {
  margin-bottom: 12px;
}

.form-top-holder .input-dropdown-holder .users-options-holder li button {
  border: none;
  outline: none;
  background: #f0f0f0;
  padding: 8px 12px;
  border-radius: 3px;
  text-align: start;
  width: 100%;
}

.selected-users-holder {
  margin-bottom: 40px;
}

.selected-users-holder .selected-users-list {
  margin-top: 10px;
  max-width: 500px;
}

.selected-users-holder .selected-users-list li {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.selected-users-holder .selected-users-list li button {
  border: none;
  outline: none;
  background-color: #eee;
  color: #333;
  padding: 5px 8px;
  font-size: 14px;
}

.selected-users-holder .selected-users-list li button:hover {
  background-color: #ccc;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
