.messages-details-wrap label {
  font-size: 16px;
}

.messages-details-wrap h2 {
  text-align: left;
  margin-bottom: 20px;
}

.messages-details-wrap p {
  max-width: 800px;
}

.messages-details-wrap ul {
  margin-bottom: 50px;
}

.messages-details-wrap ul li {
  margin-bottom: 12px;
}