@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,700');

.admin-app .contract-list .container {
  max-width: initial;
  padding: 20px;
  margin: 0;
  border: 1px solid #ecedee;
}

.admin-app .contract-list .container.active {
  display: flex;
  justify-content: space-between;
  border-color: var(--brand-green);
}

.admin-app .contract-list .container.active .details {
  flex: 1;
}

.admin-app .contract-list .container.active .selected-icon
 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-app .ant-layout-aside {
  position: relative;
  min-height: 100%;
}

.admin-app .ant-layout-aside .ant-layout-sider {
  width: 224px;
  position: absolute;
  /*overflow: auto;*/
  padding-bottom: 24px;
  height: 100%;
}

.admin-app .ant-layout-aside .ant-layout-sider > .ant-menu {
  margin-bottom: 20px;
}

.admin-app .ant-layout-aside .ant-layout-sider {
  background: white !important;
  min-height: 100vh;
}

.admin-app .ant-layout-aside .ant-layout-header {
  background: #fff;
  height: 64px;
  border-bottom: 1px solid #e9e9e9;
}

.admin-app .ant-layout-aside .ant-layout-breadcrumb {
  margin: 7px 0 -17px 24px;
}

.admin-app .ant-layout-aside .ant-layout-main {
  margin-left: 224px;
}

.admin-app .ant-layout-aside .ant-layout-container {
  margin: 0 16px 24px 16px;
}

.admin-app .ant-layout-aside .ant-layout-content {
  background: #fff;
  color: inherit;
  padding: 24px;
}

.admin-app .ant-layout-aside .ant-layout-footer {
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 12px;
  color: #999;
  background: #fff;
  border-top: 1px solid #e9e9e9;
  width: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.admin-app h1 {
  font-weight: 300;
  color: rgb(82, 82, 76);
  text-align: center;
  font-size: 46px !important;
  margin-bottom: 40px !important;
  position: relative;
  word-wrap: break-word;
}

.admin-app h2 {
  font-weight: 300;
  color: rgb(82, 82, 76);
  text-align: center;
  font-size: 23px;
  margin-bottom: 40px;
  position: relative;
  word-wrap: break-word;
}

.admin-app .marginBottom1 {
  margin-bottom: 10px !important;
}

.admin-app .marginBottom2 {
  margin-bottom: -10px !important;
}

.admin-app .marginBottom3 {
  margin-bottom: 0px !important;
}
.ant-modal-body .marginTop1,
.admin-app .marginTop1 {
  margin-top: 10px;
}

.admin-app .main {
  padding-top: 150px;
  padding-bottom: 75px;
}

.admin-app .divider2Wrapper {
  width: 50%;
  margin: 0 auto;
}

.admin-app .divider2 {
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  bottom: 0px;
  margin-top: 10px;
}

.admin-app .cf:before,
.admin-app .cf:after {
  content: '';
  display: block;
  clear: both;
}

.admin-app form label {
  font-size: 16px;
}

.admin-app .modalSubjectWrapper .ant-select-selection {
  border-radius: 4px !important;
  background: #bdb2b0;
  color: white;
  border: none !important;
  box-shadow: none !important;
  font-size: 16px;
  height: 40px !important;
  padding-left: 7px;
  padding-top: 7px;
}

.admin-app div.btn {
  padding-top: 7px;
}

.App .admin-app .consultationsList button,
.admin-app div.btn,
.admin-app .modalSubjectWrapper button {
  width: 100%;
  height: 40px;
  border: 1px solid #333;
  background: white;
  cursor: pointer;
  outline: none;
  color: #333;
  /*border-radius: 20px;*/
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
}

.App .admin-app button:hover {
  background: #eee;
}

.App .admin-app .consultationsList button.alt {
  border-color: #fabb00;
}

.admin-app .modalSubjectWrapper .ant-modal-footer {
  text-align: center;
  border: 0px;
  padding: 18px 0;
}

.admin-app .modalSubjectWrapper .ant-modal-header {
  border: 0px;
}

.admin-app .modalSubjectWrapper .ant-modal-body {
  padding: 0 16px;
}
.css-1gtu0rj-indicatorContainer, 
.css-tlfecz-indicatorContainer {
  padding: 3px!important;
}
.modalSubjectWrapper .css-1pahdxg-control,
.modalSubjectWrapper .css-yk16xz-control {
  height: 28px!important;
  min-height: 28px!important;
}
.modalSubjectWrapper .css-1pahdxg-control,
.modalSubjectWrapper .css-yk16xz-control {
  border-radius: 4px !important;
}
.modalSubjectWrapper .ant-input,
.modalSubjectWrapper .css-yk16xz-control *,
.modalSubjectWrapper .css-1pahdxg-control *,
.modalSubjectWrapper .css-yk16xz-control .css-1wa3eu0-placeholder,
.modalSubjectWrapper .css-1pahdxg-control .css-1wa3eu0-placeholder:focus-within,
.modalSubjectWrapper .css-1pahdxg-control .css-1wa3eu0-placeholder,
.modalSubjectWrapper .css-yk16xz-control .css-1wa3eu0-placeholder:focus-within,
.modalSubjectWrapper .css-yk16xz-control .css-1wa3eu0-placeholder {
  font-size: 12px!important;
}
.modalSubjectWrapper .css-1pahdxg-control:focus-within svg,
.modalSubjectWrapper .css-1pahdxg-control svg,
.modalSubjectWrapper .css-yk16xz-control:focus-within svg,
.modalSubjectWrapper .css-yk16xz-control svg {
  fill: gray;
}
.modalSubjectWrapper .css-1pahdxg-control:focus-within,
.modalSubjectWrapper .css-1pahdxg-control,
.modalSubjectWrapper .css-yk16xz-control:focus-within,
.modalSubjectWrapper .css-yk16xz-control {
  padding: 0;
}

.App .admin-app .consultationsList button.primary,
.modalSubjectWrapper .ant-btn-primary,
.admin-app .addNewContractButton button {
  background: #fabb00 !important;
  border-color: #fabb00 !important;
  color: white !important;
}

.admin-app .ant-btn-primary {
  background: #fabb00 !important;
  border-color: #fabb00 !important;
  color: white !important;
}

.admin-app .ant-btn-primary > span {
  background: #fabb00 !important;
  border-color: #fabb00 !important;
  color: white !important;
}

.admin-app .ant-upload.ant-upload-select-picture-card {
  width: 130px !important;
  min-height: 80px;
  height: auto !important;
  display: flex !important;
  align-items: center;
}
.admin-app .ant-upload {
  padding: 0 0 !important;
}

.App .admin-app .consultationsList button.primary:hover,
.admin-app .modalSubjectWrapper .ant-btn-primary:hover,
.admin-app .addNewContractButton button:hover {
  background: #ee9e20 !important;
}

.App .admin-app .consultationsList button.additionalStyle1,
.admin-app .modalSubjectWrapper .ant-modal-footer button {
  width: initial;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: bold;
  height: 30px;
  font-size: 14px;
  min-width: 88px !important;
}
.admin-app .modalSubjectWrapper form {
  width: 100% !important;
  margin: 0 auto !important;
}

.App .admin-app .consultationsList button.danger,
.userApp button.danger {
  background: #e00616;
  border-color: #e00616;
  color: white;
}

.App .admin-app .consultationsList button.danger:hover,
.userApp button.danger:hover {
  background: #d00506;
}

.userApp h1 > a {
  font-family: 'Source Sans Pro', sans-serif;
  position: absolute;
  left: 0;
  top: 10px;
  color: black;
  text-transform: uppercase;
  font-size: 20px;
}

.userApp h1 > a > i {
  color: #fabb00;
}

.admin-app .userChoiceBar {
  width: 4px;
  height: 30px;
  background-color: #fabb00;
  display: inline-block;
  margin-right: 10px;
}

.admin-app .timeSlotScheduled {
  font-weight: bold;
  color: #292929;
  position: relative !important;
  padding: 8px 0;
}

.admin-app .timeSlotScheduled .actualTime {
  padding-left: 14px;
}
.admin-app .actualTime {
  padding-bottom: 6px;
  padding-top: 6px;
}

.admin-app .timeSlotScheduled:before {
  content: '';
  height: 100% !important;
  border-left: 4px solid #fabb00;
  position: absolute;
}

.admin-app .textRight {
  text-align: right;
}

.admin-app .fontStyle1 {
  font-weight: bold;
  font-size: 18px;
  color: #292929;
  margin-bottom: 6px;
}

.admin-app .fontStyle2 {
  font-weight: bold;
  color: #292929;
  line-height: 20px;
  margin-bottom: 2px;
}

.admin-app .fontStyle3 {
  font-weight: initial;
}

.admin-app .fontSize1 {
  font-size: 14px;
}

.admin-app .fontBold {
  font-weight: bold;
}

.admin-app .fontNormal {
  font-weight: normal;
}

.admin-app .fontLight {
  font-weight: lighter;
}

.admin-app .statusIcon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.admin-app .statusIcon.active {
  background-color: #36b830;
}

.admin-app .statusIcon.pending {
  background-color: #fabb00;
}

.admin-app .statusIcon.inactive {
  background-color: #fa1700;
}

.admin-app .timeSlotsWrapper::before {
  content: '';
  width: 8px !important;
  height: 100% !important;
  background-color: #fabb00 !important;
  display: inline-block !important;
  margin-right: 10px !important;
  position: absolute;
  left: 0px;
  top: 0px;
}

.admin-app .header {
  background: white;
  border-bottom: 1px solid #fabb00;
}

.admin-app .headerPos {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
}

.admin-app .header > a > img {
  height: 80px;
}

.admin-app .header {
  text-align: center;
}

.admin-app .padding1 {
  padding-left: 10px;
}

.admin-app button > span {
  color: rgba(0,0,0,.65);
}

.admin-app a {
  color: #77645b !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}

.admin-app a:hover {
  color: #fabb00 !important;
}

.admin-app .login-validation {
  color: #e00616;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
}

.admin-app .ant-form-explain {
  color: #e00616 !important;
  padding-left: 15px;
}

.admin-app .lead {
  font-size: 20px;
}

.admin-app .download-link {
  font-size: 16px;
  font-weight: normal !important;
}

.admin-app div.form-col {
  padding: 0 10px;
}

.admin-app form.ant-form {
  margin: 0 -10px;
}

.admin-app .space-margin {
  margin: 0 -10px !important;
}

.admin-app .space > div {
  padding: 0 10px !important;
}

.admin-app .news-item {
  font-weight: 300 !important;
  font-size: 14px;
}

.admin-app .news-item h3 {
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 1em;
  color: rgb(82, 82, 76) !important;
  margin-bottom: 5px;
}

.admin-app .news-item .ni-date {
  font-weight: 300 !important;
  font-size: 15px;
  color: rgb(82, 82, 76) !important;
}

.admin-app .news-item .ni-more {
  margin-top: 7px;
  color: rgb(82, 82, 76) !important;
  font-weight: 700;
  font-size: 18px !important;
}

.admin-app a:hover .news-read-more {
  color: #fabb00;
}

.admin-app .ni-summary {
  line-height: 1.3em;
  color: rgb(82, 82, 76) !important;
  font-size: 18px;
}

.admin-app .ant-pagination-item-active {
  background-color: #fabb00 !important;
  border-color: #fabb00 !important;
}

.admin-app .ant-pagination-item,
.admin-app .ant-pagination-next,
.admin-app .ant-pagination-prev {
  border-color: #77645b !important;
}

.admin-app .ant-pagination-item:hover {
  border-color: #fabb00 !important;
}

.admin-app .ant-pagination-item:hover > a {
  color: #fabb00 !important;
}

.admin-app .ant-pagination-item-active > a:hover {
  color: #77645b !important;
}

.admin-app .ant-pagination-item-active:hover > a {
  color: #77645b !important;
}

.admin-app .ant-pagination-item-active:hover {
  border-color: #77645b !important;
}

.admin-app .event-calendar .ant-radio-group.ant-radio-group-small {
  display: none !important;
}

.admin-app .hidden {
  display: none !important;
}

.admin-app .DayPicker:focus {
  outline: none !important;
}

.admin-app .DayPicker-Day--hasEvent {
  background-color: /*#fabb00*/ #ffecb4 !important;
}

.admin-app .DayPicker-Day--isSelected {
  background-color: red !important;
  color: white !important;
}

.admin-app .event-item-date-box {
  float: left;
  width: 60px;
}

.admin-app .event-item {
  clear: both;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.3em;
  padding-bottom: 10px;
  padding-top: 10px;
  /*border-top: 1px solid gray;*/
  cursor: pointer;
}

.admin-app .profileContractListItem {
  padding: 20px;
  display: inline-block;
}

.admin-app .profileContractListItem1 {
  margin: 10px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 10px;
}

.admin-app .divider1 {
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  bottom: -10px;
}

.admin-app .event-item-date-box > div:first-child {
  font-size: 32px;
  text-align: center;
  line-height: 1em;
}

.admin-app .event-item-date-box > div:last-child {
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  line-height: 1em;
}

.admin-app .sticky3 {
  background-color: #55aaff;
  color: white;
  padding: 7px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.admin-app .sticky1 {
  background-color: #36b830;
  color: white;
  padding: 7px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.admin-app .selectStyle1 .ant-select-selection {
  color: inherit;
  background-color: transparent;
  padding: 0px;
  height: initial !important;
}

.admin-app .selectStyle1 .ant-select-selection-selected-value {
  padding-right: 17px;
  text-decoration: underline;
}

.admin-app .sticky2 {
  background-color: white;
  border-bottom: 1px solid #fabb00;
  color: inherit;
  padding: 7px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}

.admin-app .contractCard {
  border: 1px solid grey;
  padding: 20px;
}

.admin-app .translateWrapper {
  position: absolute;
  top: 26px;
  right: 13px;
  width: 80px;
}

.admin-app .translateWrapper button {
  border: 0;
}

.admin-app .translateWrapper .ant-select-selection {
  background-color: initial;
  color: #52524c;
  padding-top: 6px;
}

.admin-app .ant-select-dropdown-menu-item {
  font-size: 16px;
}

.admin-app .ant-select-dropdown {
  width: auto !important;
}

.admin-app ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.admin-app :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.admin-app ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  opacity: 1 !important;
}

.admin-app .ant-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
  opacity: 1 !important;
}

.admin-app :-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.admin-app .news-item .text-content {
  padding-left: 10px;
}

.admin-app .text-center {
  text-align: center;
}

.admin-app .DayPicker-Day--today {
  background: #fabb00 !important;
  font-weight: bold !important;
}

.admin-app .truncate {
}

.admin-app .truncate.oneRow {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-app .truncate.cancelOneRow {
  white-space: nowrap;
}

.App .admin-appLogo {
  max-width: 234px;
}

.admin-app .displayInline {
  display: inline-block;
}

.admin-app .profileContractListWrapper {
  border-top: 1px solid #dedede;
  margin-top: 60px;
  padding-top: 40px;
}

.admin-app .profileContractListWrapper .ant-card-head {
  height: auto;
}

.admin-app .contractListItem {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.admin-app .contractListItem:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.admin-app .profileContractListWrapper .contractList {
  border: 1px solid #e9e9e9;
  font-size: 16px;
  line-height: initial;
  padding: 20px;
  border-radius: 4px;
}

.admin-app .addNewContractButton {
  text-align: center;
  margin-top: 30px;
}

.admin-app .addNewContractButton button {
  width: auto;
}

@media screen and (min-width: 768px) {

  .admin-app .consultationsList {
    /*height: 313px;
    overflow-y: scroll;*/
  }
}

@media screen and (min-width: 577px) and (max-width: 590px) {

  .admin-app .consultationsSubtitle {
    margin-bottom: 18px !important;
  }
}

@media screen and (max-width: 576px) {

  .admin-app .breakOnMobile {
    display: block;
  }

  .admin-app .translateWrapper {
    position: absolute;
    top: 14px;
    right: 3px;
  }

  .admin-app .bm-burger-button {
    left: 26px !important;
  }

  .App .admin-appLogo {
    max-width: 174px;
  }

  .admin-app .timeSlotsWrapper::before {
    left: -20px;
  }

  .admin-app .main {
    padding-top: 75px;
    padding-bottom: 120px;
  }

  .admin-app .header > a > img {
    height: 70px;
  }

  .admin-app .bm-burger-button {
    top: 24px !important;
    right: 24px !important;
  }

  .admin-app .news-item .text-content {
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .admin-app .news-thumb {
    height: 250px !important;
  }

  .admin-app .feedback-form-btn {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center;
    margin-top: -30px;
    margin-bottom: 20px;
  }

  body {
    background-image: none;
  }
}

@media screen and (max-width: 430px) {

  .admin-app .consultationsSubtitle {
    margin-bottom: 18px !important;
  }
}

.admin-app .bm-burger-button {
  cursor: pointer;
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  height: 20px !important;
  width: 26px !important;
  z-index: 10000 !important;
}

.admin-app .news-thumb {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: norepeat;
}

.admin-app .phones-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 9000;
  height: 58px !important;
  width: 58px !important;
  border-radius: 50% !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-size: 30px !important;
  padding: 0 !important;
  line-height: 30px !important;
}

.admin-app .feedback-form-btn {
  position: absolute;
  top: 30px;
  right: 100px;
}

.admin-app .bm-menu-wrap {
  z-index: 90999 !important;
}

.admin-app .feedback-footer-text {
  margin-bottom: 10px;
  font-size: 18px;
}

.admin-app .news-article {
  font-size: 18px;
  font-weight: 300;
}

.admin-app .news-article p {
  margin-bottom: 30px;
}

.admin-app .DayPicker-NavButton--next {
  right: 32px !important;
}

.admin-app .DayPicker-NavButton--prev {
  left: 32px !important;
}

.admin-app .DayPicker-Day {
  font-size: 18px !important;
}

.admin-app .DayPicker-Caption {
  font-size: 18px !important;
}

.admin-app .DayPicker-Weekday {
  font-size: 14px !important;
}

.admin-app .welcome > h1 {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.admin-app .welcome > h2 {
  margin-bottom: 35px !important;
  font-weight: 300;
  font-size: 32px;
  text-align: center;
}

.admin-app .welcome > p {
  text-align: center;
}

.admin-app .file-table {
  width: 100%;
}

.admin-app .file-table td:nth-child(2) {
  text-align: right;
  font-size: 16px;
}

.admin-app .ant-rate-star {
  text-shadow: 2px 2px rgba(0, 0, 0, 0.4);
}

.admin-app .ant-rate-star-content {
  text-shadow: none;
}

.admin-app .consultation-break-frist-row > div {
  padding-left: 10px;
  padding-right: 10px;
}

.admin-app .consultation-break-frist-row > div:first-child {
  padding-left: 0px;
  padding-right: 10px;
}

.admin-app .consultation-break-frist-row > div:last-child {
  padding-left: 10px;
  padding-right: 0px;
}

.admin-app .row-spacer > div {
  padding: 0 10px 0 10px;
}

.admin-app .validation {
  color: #e00616;
  position: absolute;
  bottom: 6px;
  left: 25px;
}

.admin-app .ant-input.table-filter {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 250px;
}

.admin-app input.TextInput {
  background-color: #fff;
  background-image: none;
  background: #bdb2b0;
  border-radius: 4px;
  border: none !important;
  box-shadow: none !important;
  color: white;
  cursor: text;
  display: inline-block;
  font-size: 16px;
  height: 40px;
  line-height: 1.5;
  padding: 0 15px;
  position: relative;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  width: 100%;
}

.admin-app .input-div {
  margin-bottom: 10px;
  display: grid;
}

.admin-app .input-div > label {
  margin-bottom: 0.5rem !important;
  color: rgba(0,0,0,.65) !important;
}

.admin-app .table-inputs > label {
  display: inline-block !important;
  font-size: 12px !important;
  color: rgba(0,0,0,.65) !important;
  margin-bottom: 0.5rem !important;
}

.admin-app .input-div .ant-checkbox {
  top: 0em;
}

.admin-app .input-div.helper {
  margin-bottom: -5px;
}

.admin-app .helper > .helper {
  font-size: 14px;
}

.admin-app .input-div > div.error {
  height: 15px;
  color: red;
}

.admin-app form input[type='checkbox'] {
  margin-top: 5px;
}

.admin-app .input-div.checkbox {
  display: flex;
}

.admin-app .checkbox > label {
  display: block;
  padding-left: 15px;
  cursor: pointer;
  flex: 1;
}

.admin-app .checkbox > label > input {
  width: 13px;
  height: 13px;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
  position: relative;
  top: -5px;
  *overflow: hidden;
}

.admin-app .input-div > textarea {
  display: block;
  width: 100%;
  background: #bdb2b0;
  color: white;
  border: none ;
  box-shadow: none !important;
  font-size: 16px;
  /*line-height: 40px!important;*/
  padding: 0 15px;
}

.admin-app table {
  border-collapse: collapse;
  width: 100%;
}

.admin-app thead {
  padding: 20px;
  background: #eee;
}

.admin-app table.table th,
.admin-app table.table td {
  text-align: left;
  vertical-align: top;
  padding: 10px 15px 10px 15px;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.admin-app table.table.sap-invalid-calls-table td {
  vertical-align: middle;
}

.admin-app table.table.sap-invalid-calls-table td {
  vertical-align: middle;
}

.admin-app table.table td {
  border-bottom: 1px solid #ccc;
}

.admin-app button:disabled {
  cursor: not-allowed !important;
}

.admin-app .userProps {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 30px;
}

.admin-app .userProps > div {
  /*font-size: 18px*/
}

.admin-app .userProps > div > div {
  display: inline-block;
}

.admin-app .ant-input {
  height: 28px;
  color: rgba(0,0,0,.65);
  font-size: 12px;
}

.admin-app .ant-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.admin-app .ant-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.admin-app .ant-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1 !important;
}

.admin-app .ant-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
  opacity: 1 !important;
}

.admin-app .ant-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.6) !important;
}

.admin-app .userProps > div > div:first-child {
  width: 180px;
  font-weight: bold;
}

.admin-app .admin-consultation {
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  background: #eee;
}

.admin-app .admin-consultation-slot {
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  background: #f9f9f9;
  display: flex;
}

.admin-app .terms {
  font-size: 16px !important;
}

.notification-container {
  z-index: 100000;
  position: fixed;
  font-size: 16px !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.terms h1 {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
  text-align: left !important;
  font-size: 30px !important;
}

.terms h2 {
  text-align: left !important;
  margin-top: 10px !important;
}


button.ant-btn:hover {
  color: black;
  background: rgba(0, 0, 0, 0.05);
  border-color: black;
}

.admin-app .ant-confirm-content {
  font-size: 20px !important;
  text-align: center;
  margin: 0 !important;
}

.admin-app .ant-confirm-body > .anticon-check-circle {
  display: none !important;
}

.admin-app .ant-confirm-btns {
  float: none !important;
  text-align: center;
}

.admin-app .big-link {
  font-size: 20px;
  padding: 5px 0px;
}

.admin-app .banner-link {
  display: block;
}

.admin-app .banner-image {
  display: block;
  width: 728px;
  height: 90px;
  margin: 10px 0;
}

.admin-app .table__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
  background: #eee;
}
.admin-app .table__header > div {
  font-weight: bold;
}

.admin-app .ant-menu li.ant-menu-item {
  padding: 13px 10px 13px 24px;
}
.admin-app .ant-menu li.ant-menu-item.ant-menu-item-selected {
  background-color: #fff;
}
.admin-app .ant-menu li.ant-menu-item.ant-menu-item-selected a {
  color: var(--brand-color)!important;
}
.admin-app .ant-menu-inline .ant-menu-item:after {
  border-right: 1px solid #e9e9e9;
}
.admin-app li.ant-menu-item:hover {
  background-color: #ffffff !important;
}
.admin-app form.user-activity {
  display: flex; 
  justify-content: flex-start; 
  align-items: flex-end;
  margin-bottom: 50px;
}
.admin-app form.user-activity > div {
  margin-right: 10px;
}
.admin-app .user-activity-chart {
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
.admin-app .user-activity-chart * {
  font-size: 12px;
}