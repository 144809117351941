.form-top-holder {
  margin-bottom: 10px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 30px;
  .input-dropdown-holder {
    max-width: 250px;
    width: 100%;
    position: relative;
    input[type='text'] {
      padding: 2px 8px;
      color: hsl(0,0%,20%);
      height: 38px;
      font-size: 14px;
      width: 100%;
    }
    .users-options-holder {
      position: absolute;
      top: 44px;
      z-index: 3;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid #f0f0f0;
      padding: 12px 8px;
      overflow-y: auto;
      max-height: 350px;
      li {
        margin-bottom: 12px;
        button {
          border: none;
          outline: none;
          background: #f0f0f0;
          padding: 8px 12px;
          border-radius: 3px;
          text-align: start;
          width: 100%;
        }
      }
    }
  }
}
.selected-users-holder {
  margin-bottom: 40px;
  .selected-users-list {
    margin-top: 10px;
    max-width: 500px;
    li {
      border-bottom: 1px solid #ddd;
      padding-bottom: 5px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        border: none;
        outline: none;
        background-color: #eee;
        color: #333;
        padding: 5px 8px;
        font-size: 14px;
        &:hover {
          background-color: #ccc;
          transition: 0.3s;
        }
      }
    }
  }
}